@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");
@font-face {
  font-family: "Noto Sans KR Thin";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("./font/Noto_Sans_KR/NotoSansKR-Thin.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans KR Light";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./font/Noto_Sans_KR/NotoSansKR-Light.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans KR Regular";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./font/Noto_Sans_KR/NotoSansKR-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans KR Medium";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./font/Noto_Sans_KR/NotoSansKR-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans KR Bold";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./font/Noto_Sans_KR/NotoSansKR-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans KR Black";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./font/Noto_Sans_KR/NotoSansKR-Black.otf") format("opentype");
}

/**
 * Add the correct display in IE 9-.
 */

audio,
video {
  display: inline-block;
}

/**
  * Remove the border on images inside links in IE 10-.
  */

img {
  border-style: none;
}
