/*loading*/
.ipad-loading-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fffffff0;
  z-index: 999999;
}
.ipad-loading-wrap.relative {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  width: 100%;
  height: 52px;
}
.ipad-loading-wrap.absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 0;
}
.ipad-loading-wrap .center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 140px;
}
.ipad-loading-wrap .spinner {
  height: 100px;
}
.ipad-loading-wrap .spinner svg {
  width: 100%;
  height: 100%;
  animation: s-rotate1 0.7s infinite linear;
}
.ipad-loading-wrap .spinner .active {
  fill: none;
  stroke: #7c59ff;
  stroke-width: 4px;
  stroke-dasharray: 150;
  stroke-dashoffset: 120;
  animation: s-rotate2 1.4s infinite linear;
}
.ipad-loading-wrap .texter {
  font-size: 12px;
  text-align: center;
  color: #000;
  font-weight: 600;
  /* background-color: #ffffff50;
  box-shadow: 0px 0px 15px 6px #ffffff70; */
}
@keyframes s-rotate1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes s-rotate2 {
  0% {
    stroke-dashoffset: 120;
  }
  50% {
    stroke-dashoffset: 40;
  }
  100% {
    stroke-dashoffset: 120;
  }
}

/* jhg 고객화면일때 색상 */
.app-container .ipad-loading-wrap .spinner .active {
  stroke: #ff7a59;
}
