/* div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 10rem;
  text-align: center;
} */

/*
 jhg 20230601 : 입력화면 유효성 메시지 출력용
 <label className="hook-form">
  <span>레이블 명칭</span>
  <span>출력 메시지</span>
 </label>
 */
label.hook-form {
  display: flex !important;
  justify-content: space-between;
  align-items: flex-end;
}
label.hook-form > span:nth-child(2) {
  color: #ff7a59;
  font-size: 11px;
}

/*
 jhg 20230608 : react-datepicker css 추가
 */
.react-datepicker {
  font-size: 14px !important;
}
.react-datepicker-wrapper {
  background-color: white;
  border-radius: 0 4px 4px 0;
}
.react-datepicker-wrapper .input-regular {
  width: 100%;
}
.react-datepicker-year-header {
  font-size: 1em !important;
  height: 20px !important;
  line-height: 20px !important;
}
.react-datepicker__month {
  margin: 1em !important;
}
.react-datepicker__day--outside-month {
  color: #bdbdbd !important;
}
.react-datepicker__day {
  border-radius: 0.3em;
  font-weight: normal !important;
}
.react-datepicker__day--today {
  background-color: #00000030 !important;
}
.react-datepicker__day--selected {
  background-color: #ff7a59 !important;
  color: #fff !important;
}
.react-datepicker__close-icon {
  padding: 0 15px 0 0 !important;
}
.react-datepicker__close-icon::after {
  background-color: #bdbdbd !important;
  font-family: "Noto Sans KR Regular", sans-serif;
}
.date-pick-react label {
  font-family: "Noto Sans KR Regular", sans-serif;
  font-size: 14px;
  display: block;
  padding-bottom: 10px;
}
.date-pick-react .date-wrap {
  border-radius: 4px;
  overflow: unset;
  border: 1px solid #e9e9e9;
  position: relative;
  display: grid;
  grid-template-columns: 50px auto;
}
.date-pick-react .date-wrap::after {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 52px;
  background-color: #e9e9e9;
}
.date-pick-react .date-wrap .icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 4px 0 0 4px;
}
.date-pick-react .date-wrap .icon-wrap::after {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 50px;
  background-color: #e9e9e9;
}
