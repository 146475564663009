button {
  outline: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.btn-s {
  padding-top: 10px;
  padding-right: 14px;
  padding-bottom: 10px;
  padding-left: 14px;
}

.btn-m {
  padding-top: 12px;
  padding-right: 30px;
  padding-bottom: 12px;
  padding-left: 30px;
}

.btn-l {
  padding-top: 15px;
  padding-right: 34px;
  padding-bottom: 15px;
  padding-left: 34px;
}

.btn {
  color: #fff;
}
.btn.type-manager {
  background-color: #7c59ff;
}
.btn.type-user {
  background-color: #ff7a59;
}
.btn.type-negative {
  background-color: #bdbdbd;
}
.btn.type-warning {
  background-color: #df0032;
}
.btn.round {
  border-radius: 6px;
}
.btn.shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}
.btn:disabled {
  background-color: #bdbdbd;
  cursor: not-allowed;
}

.toggleSwitch {
  width: 40px;
  height: 20px;
  display: block;
  position: relative;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 0 16px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  margin: 30px;
  transition: all 0.2s ease-in;
}
.toggleSwitch .toggleButton {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #f03d3d;
  transition: all 0.2s ease-in;
}

.toggleInput:checked ~ .toggleSwitch {
  background: #f03d3d;
}
.toggleInput:checked ~ .toggleSwitch .toggleButton {
  left: calc(100% - 18px);
  background: #fff;
}

.modal.confirm {
  font-family: "Noto Sans KR Regular", sans-serif;
  z-index: 100000;
}
.modal.confirm .container {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content !important;
  padding: 34px !important;
}
.modal.confirm .container .context {
  padding-bottom: 14px;
  border-bottom: 1px solid #333;
}
.modal.confirm .container .btn-wrap {
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
